h1,
h2,
h3 {
    font-family: 'Academy Ho' !important;
    /* font-weight: bold; */
    color: #cd9654 !important;
}

h5 {
    /* font-family: 'Academy Ho' !important; */
    font-weight: bold;
    color: #cd9654 !important;
}

html, body {
    height: 100%;
    width: 100%;
}
.Admin-header {
    text-align: center;
}

.Admin-main {
    margin: 16px;
    display: grid;
    gap: 8px;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1.5fr;
    grid-template-areas: 
            "c1 c2 c3 c4 queue"
            "gamers gamers gamers gamers gamers";
}

.Admin-block {
    padding: 8px 16px;
}

.Admin-c1 {
    grid-area: c1;
}

.Admin-c2 {
    grid-area: c2;
}

.Admin-c3 {
    grid-area: c3;
}

.Admin-c4 {
    grid-area: c4;
}

.Admin-catastrophe {
    grid-area: catastrophe;
}

.Admin-bunker {
    grid-area: bunker;
}

.Admin-queue {
    grid-area: queue;
    height: min-content;
}

.Admin-gamers {
    grid-area: gamers;
    height: min-content;
}

.Admin-dialog h2 {
    font-family: inherit !important;
    /* font-weight: bold; */
    color: inherit !important;
}
.Game-main {
    height: 100vh;
}

.Game-img {
    height: calc(100vh - 80px);
    position: fixed;
    width: 100vw;
    top: 60px;
}

.Game-video * {
    max-height: calc(100vh - 80px);
}

.Game-minivideo {
    margin: auto;
}

.Game-minivideo * {
    max-height: 60vh;
}

.Game-mycards {
    display: grid;
    gap: 24px;
    height: 80vh;
    padding: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.Game-mycards .Game-block {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50px;
}

.Game-mycards .Game-innerblock {
    padding: 8px 16px;
    display: grid;
    align-content: stretch;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}

.Game-othercards {
    display: grid;
    gap: 24px;
    height: 90vh;
    padding: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.Game-othercards .Game-block {
    padding: 8px 16px;
    display: grid;
    align-content: stretch;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}

.Game-allcards {
    display: grid;
    gap: 8px;
    height: 80vh;
    padding: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.Game-allcards .Game-block {
    padding: 8px 16px;
    display: grid;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}

.Game-votecards {
    display: grid;
    gap: 8px;
    height: 80vh;
    padding: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.Game-votecards .Game-block {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50px;
}

.Game-votecards .Game-innerblock {
    padding: 8px 16px;
    display: grid;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}
.View-main {
    height: 100vh;
    /* display: contents; */
}

.View-img {
    height: calc(100vh - 80px);
    position: absolute;
    width: 100vw;
    /* calc(100vw - 24px); */
    top: 60px;
}

.View-video * {
    max-height: 100vh;
}

.View-minivideo{
    margin: auto;
}

.View-minivideo *{
    max-height: 60vh;
}

.View-mycards {
    display: grid;
    gap: 24px;
    height: 100vh;
    padding: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.View-mycards .View-block {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50px;
}

.View-mycards .View-innerblock {
    padding: 8px 16px;
    display: grid;
    align-content: stretch;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}

.View-othercards {
    display: grid;
    gap: 24px;
    height: 90vh;
    padding: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.View-othercards .View-block {
    padding: 8px 16px;
    display: grid;
    align-content: stretch;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}

.View-allcards {
    display: grid;
    gap: 8px;
    height: 100vh;
    padding: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.View-allcards .View-block {
    padding: 8px 16px;
    display: grid;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}

.View-votecards {
    display: grid;
    gap: 8px;
    height: 100vh;
    padding: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.View-votecards .View-block {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50px;
}

.View-votecards .View-innerblock {
    padding: 8px 16px;
    display: grid;
    background: rgb(18 18 18 / 20%);
    border-color: #cd9654 !important;
}
.Home-header {
    text-align: center;
    font-size: 100px;
    padding-top: 300px;
    margin-bottom: 40px !important;
}

.Home-div {
    display: grid;
    max-width: fit-content;
    margin: auto;
}

.Home-button {
    margin-top: 8px !important;
}